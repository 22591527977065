
import React, { useState, useEffect } from "react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import * as anchor from "@project-serum/anchor";
import { LAMPORTS_PER_SOL } from "@solana/web3.js";
import logoLeft from "./Logo/LogoB.png"
import disc from "./Social Icons/Discord_05.png"
import insta from "./Social Icons/Instagram_01.png"
import twitter from "./Social Icons/Twitter_01.png"
import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { Grid, Box } from "@mui/material";
import {
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
} from "./services/candy-machine";
import { useAlert } from 'react-alert'
import Section2 from "./components/Section2/Section2";
import Section3 from "./components/Section3/Section3";
import Countdown from "./Countdown";
export default function Mint(props) {
  const alert = useAlert()
  const [balance, setBalance] = useState();
  const [isActive, setIsActive] = useState(true); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);
  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState();
  const [alertState, setAlertState] = useState({
    open: false,
    message: "",
    severity: undefined,
  });

  const onMint = async () => {

    try {
      setIsMinting(true);
      if(isMinting){
        alert.show("Minting has started!");
      }
      
      console.log(candyMachine.program);
      if (wallet && candyMachine.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
      
          alert.show('Congratulations! Mint succeeded!')
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
     
          alert.show('Mint failed! Please try again!')
        }
      }
    } catch (error) {
      // TODO: blech:
      // alert.show('Minting failed! Try to Connect Wallet Again')
      
      let message = error.msg || "Minting failed! Please connect wallet again!";
      alert.show(message);
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
          alert.show('SOLD OUT!')
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
          alert.show('Insufficient funds to mint. Please fund your wallet.')
          console.log(message)
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
          alert.show("Minting period hasn't started yet.")
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }

  };

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet,
        props.candyMachineId,
        props.connection
      );
      console.log("IA", itemsAvailable);
      console.log("IR", itemsRemaining);
      console.log("IReddemed", itemsRedeemed);
      console.log("Go LIVE Date", goLiveDate);
      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);
      setCandyMachine(candyMachine);
      console.log(isActive);

      setIsSoldOut(itemsRemaining === 0);
    })();
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);
  return (
    <>
    <div className="topcorner">
        <WalletMultiButton />
      </div>

<div className='section1'>
 <div  >
 
   <img className="logo" width="20%" src={logoLeft} alt=""/>
 <div className="navPosition1">

                      <span className="navlink">
                      ABOUT
                      </span>
                      <span className="navlink">
                      TEAM
                      </span>
                      <span className="navlink "> 
                      FAQ
                      </span>
                  
                      </div>
                      <span>
                      </span>
            <Box className="section" sx={{flexGrow: 1 }}>
                <Grid container>
                    <Grid item xs={12} md={12} lg={6}>
                        <Grid container>
                        {/* <Grid style={{textAlign:'left'}} item xs={12} md={12} lg={12}>
                         <img width="50%" src={logoLeft} alt=""/>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                        <p className='intro'>
                        Rave Review will be releasing their first ever digital NFT collection on 26 Feb 2022 with their “women in crypto” collection – join our discord now for early whitelist access and to help us shape the future.
                        </p>
                        </Grid>
                        <Grid className="counter" item xs={12} md={12} lg={12}>
                         
                    <Countdown 		timeTillDate="11 12 2022, 23:59 pm" 
                    timeFormat="MM DD YYYY, h:mm a"  />
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                        <Grid alignItems="center" container>
                        <Grid style={{textAlign:'center'}} item xs={12} md={12} lg={5}>
                        <button
                          className="btn"
                          disabled={isSoldOut || isMinting || !isActive}
                          onClick={onMint}
                        >
                          {isSoldOut
                            ? "SOLD OUT"
                            : isActive
                            ? isMinting
                              ? "Minting"
                              : "MINT NOW"
                            : "Count Down"}
                        </button>
                        </Grid>
                        <Grid  item xs={0} md={0} lg={0}>

                        </Grid>
                        <Grid className="social" item xs={12} md={3} lg={4}>
                        <img className="disc" src={disc} alt=""/>
                    
                        <img className="disc"  src={insta} alt=""/>
                
                   
                        <img className="disc" src={twitter} alt=""/>
                     
                        </Grid>
                        <Grid item xs={4} md={4} lg={4}>
                        </Grid>
                        </Grid>
                        </Grid>
      
                        </Grid>
                    </Grid>

                </Grid>
            </Box>
             
        </div>


        </div>

        <Section2/>
        <Section3/>

   
    </>
  );
}
