import React from 'react'
import { Grid, Box } from "@mui/material";
import "./Section2.css"
function Section2() {
    return (
        <div className='section2'>
            <Box className="section" sx={{flexGrow: 1 }}>
                <Grid container style={{textAlign:'left'}}>
                    <Grid item xs={12} md={12} lg={6}>
                        <Grid container>
                        {/* <Grid style={{textAlign:'left'}} item xs={12} md={12} lg={12}>
                         <img width="50%" src={logoLeft} alt=""/>
                        </Grid> */}
                        <Grid item xs={12} md={12} lg={12}>
                        <p className='intro2'>
                        Rave Review is a Swedish upcycling high fashion brand... Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed efficitur erat egestas, fermentum tellus a, commodo dolor. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Cras finibus neque nec tincidunt imperdiet. Integer volutpat elementum tortor, convallis aliquam velit.  </p>
                        </Grid>
                        <Grid style={{color:'white',textAlign:'left'}} item xs={12} md={12} lg={12}>
                            <h2>TEAM</h2>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            
                        <Grid container>
                            
                        <Grid item xs={10} md={10} lg={10}>  
                        <Grid container>
                                               
                        <Grid item xs={2} md={2} lg={2}>
                        <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>         
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}> 
                           <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>
                            
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>  
                            <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>
                        <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>      
                        </Grid>

                        </Grid>       
                        </Grid>

                        </Grid>
                        </Grid>


                        <Grid item xs={12} md={12} lg={12}>
                        <Grid container>
                        <Grid item xs={10} md={10} lg={10}>  
                        <Grid container>
                                               
                        <Grid item xs={2} md={2} lg={2}>   
                            <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>       
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>   
                           <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>        
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>   
                           <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>        
                        </Grid>
                        <Grid item xs={2} md={2} lg={2}>  
                             <Grid container>
                               <Grid item xs={12} md={12} lg={12}>
                               <span className="dot"></span>  
                               </Grid>
                               <Grid item xs={12} md={12} lg={12}>
                               XXXXX  
                               </Grid>
                           </Grid>       
                        </Grid>
                        
                        </Grid>       
                        </Grid>

                        </Grid>
                        </Grid>
      
                        </Grid>
                    </Grid>

                </Grid>
            </Box>
        </div>
    )
}

export default Section2
